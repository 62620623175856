const routes = [
    {
        path: ["/", "/home"],
        exact: true,
        component: "Home",
    },
    // login route
    {
        path: ["/login"],
        exact: true,
        component: "Login",
    },
];

export default routes;
