import { useEffect, useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
    HeaderSection,
    LogoContainer,
    Burger,
    NotHidden,
    Menu,
    CustomNavLinkSmall,
    Label,
    Outline,
    Span,
} from "./styles";
import { NavLink, useHistory } from "react-router-dom";

const Header = ({ t }: { t: TFunction }) => {
    const [visible, setVisibility] = useState(false);
    let history = useHistory();

    const toggleButton = () => {
        setVisibility(!visible);
    };

    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                });
                setVisibility(false);
                history.replace("/#" + id);
            } else {
                // navigate home and scroll to id
                history.push("/#" + id);

                // set an observer to scroll to the id
                const observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (mutation.type === "childList") {
                            const element = document.getElementById(
                                id
                            ) as HTMLDivElement;
                            if (element) {
                                element.scrollIntoView({
                                    behavior: "smooth",
                                });
                                setVisibility(false);

                                // disconnect the observer
                                observer.disconnect();
                            }
                        }
                    });
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });
            }
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => scrollTo("about")}>
                    <Span>{t("About")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
                    <Span>{t("Mission")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("product")}>
                    <Span>{t("Product")}</Span>
                </CustomNavLinkSmall>

                <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
                    <Span>{t("Contact")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall style={{ width: "180px" }}>
                    <Span>
                        <NavLink to={"/login"}>
                            <Button>{t("B2B Login")}</Button>
                        </NavLink>
                    </Span>
                </CustomNavLinkSmall>
            </>
        );
    };

    return (
        <HeaderSection>
            <Container>
                <Row justify="space-between">
                    <LogoContainer to="/" aria-label="homepage">
                        <SvgIcon src="logo.svg" width="120px" height="64px" />
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem />
                    </NotHidden>
                    <Burger onClick={toggleButton}>
                        <Outline />
                    </Burger>
                </Row>
                <Drawer closable={false} open={visible} onClose={toggleButton}>
                    <Col style={{ marginBottom: "2.5rem" }}>
                        <Label onClick={toggleButton}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline />
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem />
                </Drawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
